import React from "react";
import { Link } from "react-router-dom";
import Hyp from "../../img/hyyp.png";
import { Helmet } from "react-helmet";
const Hypnose = () => {
  return (
    <>
      <Helmet>
        <title>Hypnose - Mental Body Energy </title>
        <meta
          name="description"
          content="L’Hypnose est définie comme un état modifié de conscience qui
          permet à la personne hypnotisée d’être plus réceptive aux
          suggestions afin de résoudre une problématique physique ou
          psychique."
        />
      </Helmet>
      <section className="magne-section">
        <div className="im">
          <img src={Hyp} alt="Hypnose mental body energy" />
        </div>
        <section className="infos-soins-details">
          <div className="col info-text-m">
            <h2>L'HYPNOSE</h2>
            <p>
              L’Hypnose est définie comme un état modifié de conscience qui
              permet à la personne hypnotisée d’être plus réceptive aux
              suggestions afin de résoudre une problématique physique ou
              psychique.
            </p>
            <p>
              Principe : <br /> D’une voix calme et posée, le thérapeute invite
              le patient à se représenter un lieu, un moment agréable, ou bien à
              fixer son attention sur un objet. Ce processus d’immersion le
              plonge dans un état de confusion mentale où son jugement ainsi que
              sa perception de l’environnement et du temps sont modifiés.
              L’inconscient et le conscient sont ainsi dissociés, le
              self-control comme désactivé. Par le choix des mots et la
              suggestion de métaphores, le thérapeute amène le patient à se
              détourner de la douleur, de son environnement , ou encore à
              trouver des solutions pour résoudre certains blocages (peurs,
              addictions).
            </p>
            <h2>DES EXEMPLES DE BIENFAITS</h2>
            <p>
              <div className="red row">
                <ul>
                  <li>
                    mieux gérer les situations de stress ou calmer les nerfs
                  </li>
                  <li>Une opération chirurgicale</li>
                  <li>Mieux gérer les douleurs , voir les supprimer</li>
                  <li>Aider à améliorer le sommeil</li>
                  <li>Stopper les bouffées de chaleur lors de la ménopause</li>
                </ul>
                <ul>
                  <li>Aider à maigrir</li>
                  <li>Aider à arrêter le tabac ou les addictions</li>
                  <li>La liste est longue, n’hésitez pas à me contacter</li>
                </ul>
              </div>
            </p>
            <p>
              Je pratique l’Hypnose Ericksonienne et classique. En aucun cas le
              patient n’est manipulé ou influencé à son insu et la personne peut
              à tout moment quitter son état d’hypnose. L’hypnose Ericksonienne
              induit un état de rêve (Etat modifié de conscience) qui permet
              l’accès à l’inconscient. Son but est de permettre à la personne de
              puiser en elle les ressources qui solutionneront sa difficulté.
            </p>
            <h2>AVERTISSEMENTS</h2>
            <p>
              En tant que Thérapeute énergéticienne, Hypnothérapeute et
              Réflexologue je ne pose aucun diagnostique médical et ne fait
              aucune prescription. La thérapie énergétique, les soins
              énergétiques, l’EFT, la Réflexologie et l’Hypnose ne constituent
              pas un acte médical; ils ne dispensent pas de consulter votre
              médecin. Ils ne remplacent ni n’interrompent, et ce, EN AUCUN CAS,
              un traitement ou un suivi médical en cours. Ils ne sont qu’une
              aide complémentaire qui tente de stimuler les aptitudes naturelles
              d’autoguérison.
            </p>
          </div>
        </section>
        <div className="tarif-section">
          <h1>Tarifs pour une consultation en hypnose</h1>
          <div className="border"></div>
          <section className="tarif-boxes">
            <div className="row">
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour enfant</p>
                  <p className="age">(-16ans) - 1h</p>
                </div>
                <div className="main-box">
                  <p>60€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour adulte</p>
                  <p className="age"> 1h</p>
                </div>
                <div className="main-box">
                  <p>65€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
            </div>
            <div className="rdv-button">
              <button>
                <Link to="/contact">Prendre rendez-vous</Link>
              </button>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Hypnose;
