import React from "react";
import "./Quisuisje.css";
import leslucs from "../../img/leslucs.jpeg";
import Christine from "../../img/christine1.jpg";
import facebookpng from "../../img/facebook.png";
import instagrampng from "../../img/instagram.png";
import { Helmet } from "react-helmet";
const Quisuisje = () => {
  return (
    <>
      <Helmet>
        <title>Qui suis je - Christine Énergéticienne</title>
        <meta
          name="description"
          content="J’ouvre mon nouveau cabinet thérapeutique le 10 janvier 2022 aux Lucs sur Boulogne. Et pour des urgences particulières ( fièvres, torticolis, brulures…..), je peux"
        />
      </Helmet>
      <section className="qui-section">
        <img src={leslucs} alt="" />
        <h3>Energeticienne aux Lucs sur Boulogne</h3>
        <section className="info-perso">
          <div className="row">
            <img
              src={Christine}
              alt="Christine Energeticienne aux Lucs sur Boulogne"
            />
            <p>
              Je m’appelle Christine. <br /> <br />
              J’aime la littérature, la poésie, la force et le poids des mots
              mais surtout, j’ai un amour inconditionnel pour l’être humain, sa
              fragilité comme sa force. Ses peines, ses angoisses, ses phobies,
              ses méfiances, ses douleurs physiques ou morales, ses
              questionnements m’ont toujours interpellé. <br />
              La société moderne en constante mutation contribue et accentue ces
              émotions et sentiments négatifs. Aujourd’hui plus que jamais
              l’individu aspire à une aide extérieure, bienveillante, pour
              surmonter ses problématiques. <br /> C’est la raison pour laquelle
              je me suis tournée vers la médecine alternative. Je me suis formée
              à différentes pratiques dans le seul but de soulager, amoindrir,
              voir supprimer les maux. <br /> Je suis énergéticienne (
              praticienne en magnétisme et en LaHoChi ), je suis praticienne en
              EFT ( Emotional Freedom Techniques), je suis Hypnothérapeute ( en
              hypnose Ericksonienne et Classique) et je suis Réflexologue
              plantaire, palmaire et faciale. J’aspire à vous soulager de vos
              maux, à vous aider dans de nouveaux comportements, à vous
              accompagner dans votre chemin de vie ( pour devenir créateur et
              acteur de votre vie). <br />
              Le mal-être n’est pas immuable. Voici une citation de Wolfgang
              Amadeus Mozart que j’affectionne: <br /> “ Vivre bien et vivre
              heureux, voilà deux choses différentes. Et sans un peu de magie,
              il est certain que je ne connaîtrai pas la seconde.” <br /> <br />
              Je peux faire partie de cette magie pour vous soutenir dans votre
              bien-être et devenir enfin heureux. <br /> La douleur n’est pas
              une fatalité. Le Dr René Leriche dit : <br /> “ La seule douleur
              supportable est celle des autres. “ <br /> Votre douleur vous est
              propre et à prendre sérieusement en considération. Je peux
              réveiller en vous les capacités qui vous sont propres pour vous
              apaiser. <br /> Vous l’aurez compris, selon votre sensibilité, je
              propose des soins adaptés à votre problématique. Mes thérapies
              utilisent des techniques non médicamenteuses et non médicales.
              Elles impliquent le corps et l’esprit pour favoriser le changement
              bénéfique d’un état physique ou mental. <br /> A la suite de mes
              thérapies vous ressentirez un état de bien-être, un sentiment de
              bonheur ou de réalisation de soi. <br /> Alors n’hésitez plus et
              venez adoucir votre quotidien. <br /> J’ouvre mon nouveau cabinet
              thérapeutique le 10 janvier 2022 aux Lucs sur Boulogne. Et pour
              des urgences particulières ( fièvres, torticolis, brulures…..), je
              peux travailler à distance à l’aide d’une photographie. <br />
              Vous voulez vous recharger en énergie, vous ré-équilibrer, vous
              sentir reposé….mais vous n’avez pas de temps à perdre….je peux
              vous faire une séance de LaHoChi á distance. Nous conviendrons
              alors d’une heure où vous pourrez rester tranquille, allongé, pour
              recevoir le soin. Là aussi une photographie vous sera demandée.
            </p>
          </div>
        </section>
        <div className="border"></div>
        <p className="finishingline">
          Rien n’est à jamais figé, on peut trouver des solutions à toute
          problématique.
        </p>
      </section>
    </>
  );
};

export default Quisuisje;
