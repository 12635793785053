import React from "react";
import "./Footer.css";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import MailchimpSubscribe from "react-mailchimp-subscribe";
const Footer = () => {
  const url =
    "https://outlook.us5.list-manage.com/subscribe/post?u=93e7d1779f167b4c0e4b69a53&amp;id=1157cbc52e";

  const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });

    return (
      <div>
        {status === "sending" && (
          <div style={{ color: "blue" }}>sending...</div>
        )}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <div className="row innp">
          <input
            style={{ fontSize: "13px", padding: 5 }}
            ref={(node) => (email = node)}
            type="email"
            placeholder="Your Email"
          />
          <button style={{ fontSize: "13px", padding: 5 }} onClick={submit}>
            OK
          </button>
        </div>
      </div>
    );
  };
  return (
    <div class="footer-dark">
      <footer>
        <section>
          <div className="col seccs">
            <h3 className="conn">CONTACT</h3>
            <ul>
              <a
                href="mailto: christine.merlet1@orange.fr"
                target="_blank"
                rel="noreferrer"
              >
                <div className="row oneli">
                  <AiOutlineMail className="icon" />
                  <li>mentalbodyenergy@gmail.com</li>
                </div>
              </a>
              <a href="tel: 07-86-54-73-75">
                <div className="row oneli">
                  <AiOutlinePhone className="icon" />
                  <li>+33 6 72 63 75 55</li>
                </div>
              </a>
              <a
                href="https://www.google.it/maps/place/10+Rue+des+Martyrs,+85170+Les+Lucs-sur-Boulogne,+France/@46.8446835,-1.487996,17z/data=!3m1!4b1!4m5!3m4!1s0x4805cd11d56c22bb:0xa90fd1ac5dfab1fc!8m2!3d46.8446799!4d-1.4858073"
                target="_blank"
                rel="noreferrer"
              >
                <div className="row oneli">
                  <FiMapPin className="icon" />
                  <li className="address">
                    10 rue des martyrs, les lucs sur boulogne, 85170
                  </li>
                </div>
              </a>
            </ul>
          </div>
          <div className="col seccs">
            <h3 className="spacing">RESEAU SOCIAL</h3>

            <a
              href="https://www.instagram.com/mentalbodyenergy/?utm_medium=copy_link"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram className="icon2" />
            </a>
          </div>
          <div className="col seccs12">
            <h3 className="newsletter">NEWSLETTER</h3>
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />
          </div>
        </section>
      </footer>
    </div>
  );
};

export default Footer;
