import React from "react";
import "./ReflexologieM.css";
import Main from "../../img/palmaire.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const ReflexologieM = () => {
  return (
    <>
      <Helmet>
        <title>Réflexologie palmaire - Mental Body Energy </title>
        <meta
          name="description"
          content="Elle consiste à masser les mains en stimulant les points réflexes
          de celle-ci. La main est, comme le pied, un reflet de notre corps
          et masser la main active des points réflexes."
        />
      </Helmet>
      <section className="magne-section">
        <div className="im">
          <img src={Main} alt="réfléxologie palmaire mental body energy" />
        </div>
        <section className="infos-soins-details">
          <div className="col info-text-m">
            <h2>LA RÉFLEXOLOGIE PALMAIRE</h2>
            <p>
              Elle consiste à masser les mains en stimulant les points réflexes
              de celle-ci. La main est, comme le pied, un reflet de notre corps
              et masser la main active des points réflexes. <br />
              Comme les pieds, les mains sont la représentation projetée de nos
              organes. La réflexologie palmaire a une action calmante sur le
              système neuro-musculo-squelettique. Elle est complémentaire à la
              réflexologie plantaire en vue de soulager les tensions
              articulaires, musculaires, tissulaires, et d’apporter une
              sensation de légèreté.
            </p>
            <h2>DES EXEMPLES DE BIENFAITS</h2>
            <p>
              <ul>
                <li>Les douleurs liés à l’arthrite</li>
                <li>Les douleurs de l’arthrose</li>
                <li>Les rhumatismes</li>
                <li>Le canal carpien</li>
              </ul>
            </p>
            <p>
              Ce soin peut se recevoir en position assise ou allongé. Il est
              très apprécié chez les personnes âgées. Il est également
              recommandé aux personnes alitées, aux femmes enceintes, aux
              enfants et toutes personnes ne pouvant pas recevoir de
              réflexologie plantaire.
            </p>
            <h2>AVERTISSEMENTS</h2>
            <p>
              En tant que Thérapeute énergéticienne, Hypnothérapeute et
              Réflexologue je ne pose aucun diagnostique médical et ne fait
              aucune prescription. La thérapie énergétique, les soins
              énergétiques, l’EFT, la Réflexologie et l’Hypnose ne constituent
              pas un acte médical; ils ne dispensent pas de consulter votre
              médecin. Ils ne remplacent ni n’interrompent, et ce, EN AUCUN CAS,
              un traitement ou un suivi médical en cours. Ils ne sont qu’une
              aide complémentaire qui tente de stimuler les aptitudes naturelles
              d’autoguérison.
            </p>
          </div>
        </section>
        <div className="tarif-section">
          <h1>Tarifs pour une consultation en réflexologie palmaire</h1>
          <div className="border"></div>
          <section className="tarif-boxes">
            <div className="row">
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour enfant</p>
                  <p className="age">(-16ans) - 1h</p>
                </div>
                <div className="main-box">
                  <p>45€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour adulte</p>
                  <p className="age"> 1h</p>
                </div>
                <div className="main-box">
                  <p>50€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
            </div>
            <div className="rdv-button">
              <button>
                <Link to="/contact">Prendre rendez-vous</Link>
              </button>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default ReflexologieM;
