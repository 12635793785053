import React from "react";
import "./header.css";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import Logo from "../../img/logo.png";
import Menu from "../Menu";
import BurgerIcon from "../BurgerIcon";
const Header = () => {
  const contentStyle = {
    background: "rgba(255,255,255,0",
    width: "80%",
    border: "none",
  };
  return (
    <>
      <header>
        <nav>
          <ul>
            <Link to="/">
              <img src={Logo} alt="logo Mental Body Energy" />
            </Link>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li className="re">
              <Link to="/qui-suis-je">Qui suis-je</Link>
            </li>
            <li>
              <Link to="/les-differents-soins-energetiques">
                Les différents soins énergétiques
              </Link>
            </li>
            <li className="re">
              <Link to="/temoignages">Témoignages</Link>
            </li>

            <h1>
              <Link to="/">Mental Body Energy </Link>
            </h1>
            <li className="appears">
              A propos
              <ul className="sous">
                <li>
                  <Link to="/qui-suis-je">Qui suis-je</Link>
                </li>
                <li>
                  <Link to="/temoignages">Témoignages</Link>
                </li>
              </ul>
            </li>
            <button className="rdv">
              <Link to="/contact">Prendre RDV</Link>
            </button>
          </ul>
        </nav>
      </header>
      <Popup
        modal
        overlayStyle={{ background: "rgba(255,255,255,0.98" }}
        contentStyle={contentStyle}
        closeOnDocumentClick={false}
        trigger={(open) => <BurgerIcon open={open} />}
      >
        {(close) => <Menu close={close} />}
      </Popup>
    </>
  );
};

export default Header;
