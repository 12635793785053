import React from "react";
import { Link } from "react-router-dom";

export default ({ close }) => (
  <div className="menu">
    <ul>
      <li onClick={close}>
        <Link to="/">Accueil</Link>
      </li>
      <li onClick={close}>
        <Link to="/qui-suis-je">Qui suis-je</Link>
      </li>
      <li onClick={close}>
        <Link to="/les-differents-soins-energetiques">
          Les différents soins énergétiques
        </Link>
      </li>
      <li onClick={close}>
        <Link to="/temoignages">Témoignages</Link>
      </li>
      <li onClick={close}>
        <Link to="/contact">Contact</Link>
      </li>
    </ul>
  </div>
);
