import React from "react";
import eft from "../../img/Mask4.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const EFT = () => {
  return (
    <>
      <Helmet>
        <title>EFT - Mental Body Energy </title>
        <meta
          name="description"
          content="L’EFT est une technique de libération émotionnelle. C’est une
          thérapie de nouvelle génération qui agit sur les dimensions
          cognitives, émotionnelles et corporelles"
        />
      </Helmet>
      <section className="magne-section">
        <div className="im">
          <img src={eft} alt="eft mental body energy" />
        </div>
        <section className="infos-soins-details">
          <div className="col info-text-m">
            <h2>L'EFT</h2>
            <p>
              L’EFT est une technique de libération émotionnelle. C’est une
              thérapie de nouvelle génération qui agit sur les dimensions
              cognitives, émotionnelles et corporelles. Cette approche repose
              notamment sur la stimulation des méridiens, ces points
              d’acupressure agissant sur le système nerveux parasympathique. En
              tapotant avec les doigts certains points d’entrée des méridiens,
              tout en verbalisant le problème qui vous perturbe, les émotions
              correspondantes jusqu’alors stockées dans votre corps
              disparaissent.
            </p>

            <p>
              L’EFT peut traiter tous les troubles physiques ou psychiques (
              douleurs, deuil, stress, psoriasis, zona, tristesse, culpabilité,
              prise de parole en public, phobies, addictions, la liste ne
              s’arrête pas là )...
            </p>
            <p>
              Les résultats sont surprenants souvent dès la première séance{" "}
              <br />
              1ère consultation:
            </p>
            <ul>
              <li>Prise de contact et évaluation du problème</li>
              <li>Explication du procédé de l’EFT</li>
              <li>Mise en place de procédés de relaxation</li>
            </ul>
            <p>2ème consultation:</p>
            <ul>
              <li>Séance d’EFT</li>
            </ul>
            <p>
              Selon la problématique le nombre de séance peut varier. Les séance
              seront espacées de 2 à 4 semaines.
            </p>
            <h2>AVERTISSEMENTS</h2>
            <p>
              En tant que Thérapeute énergéticienne, Hypnothérapeute et
              Réflexologue je ne pose aucun diagnostique médical et ne fait
              aucune prescription. La thérapie énergétique, les soins
              énergétiques, l’EFT, la Réflexologie et l’Hypnose ne constituent
              pas un acte médical; ils ne dispensent pas de consulter votre
              médecin. Ils ne remplacent ni n’interrompent, et ce, EN AUCUN CAS,
              un traitement ou un suivi médical en cours. Ils ne sont qu’une
              aide complémentaire qui tente de stimuler les aptitudes naturelles
              d’autoguérison.
            </p>
          </div>
        </section>
        <div className="tarif-section">
          <h1>Tarifs pour une consultation en hypnose</h1>
          <div className="border"></div>
          <section className="tarif-boxes">
            <div className="row">
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour enfant</p>
                  <p className="age">(-16ans) - 1h</p>
                </div>
                <div className="main-box">
                  <p>45€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour adulte</p>
                  <p className="age"> 1h</p>
                </div>
                <div className="main-box">
                  <p>50€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
            </div>
            <div className="rdv-button">
              <button>
                <Link to="/contact">Prendre rendez-vous</Link>
              </button>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default EFT;
