import React from "react";
import "./Magnetisme.css";
import magnee from "../../img/magnee.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const Magnetisme = () => {
  return (
    <>
      <Helmet>
        <title>Magnétisme - Mental Body Energy </title>
        <meta
          name="description"
          content="Le magnétisme est une forme
          d’énergie positive qui aide à réguler et à rééquilibrer les
          énergies négatives."
        />
      </Helmet>
      <section className="magne-section">
        <div className="im">
          <img src={magnee} alt="Magnétisme mental body energy" />
        </div>
        <section className="infos-soins-details">
          <div className="col info-text-m">
            <h2>MAGNÉTISME</h2>
            <p>
              Toute personne vivante est dotée d’une énergie plus ou moins
              puissante, mais accessible à tous. Certains en sont conscients et
              l’utilisent pour eux-même et les autres, tandis que d’autres n’y
              parviennent pas ou l’ignorent. Le magnétisme est une forme
              d’énergie positive qui aide à réguler et à rééquilibrer les
              énergies négatives. Le soin s‘effectue par une apposition des
              mains, sans contact avec le corps de la personne qui reste
              allongée sur une table de massage, habillée. Le soin dure entre 30
              minutes et 1 heure. Il peut être pratiqué au cabinet comme à
              distance et l’efficacité reste la même.
            </p>
            <p>
              Le magnétiseur ne prescrit jamais de médicaments et ses mains
              constituent son unique outil de travail. Il ne se substitue pas
              aux soins du médecin traitant du patient et ne recommande jamais
              d’arrêter un traitement en cours. Le magnétiseur possède une
              énergie puissante, aussi bien physique que morale, qu’il partage
              avec ses consultants. Il est animé d’un désir sincère d’aider les
              personnes en souffrance.
            </p>
            <p>
              Une séance de magnétisme est relaxante et ne procure aucune
              souffrance physique. Le magnétiseur utilise ses mains pour
              canaliser et diriger l’énergie nécessaire pour résoudre le ou les
              problèmes rencontrés. Le patient peut ressentir une chaleur
              variable au niveau de la zone magnétisée, des picotements dans le
              bras ou des frissons. Toutes ces réactions sont positives pour la
              suite du traitement. Il est aussi possible que la personne ne
              ressente rien mais les effets des énergies agissent. <br />
              Contre-indications:
              <ul>
                <li>les calculs rénaux et vésicaux</li>
                <li>en cas de tuberculose</li>
                <li>en cas d’ulcère à l’estomac</li>
              </ul>
            </p>
            <h2>POUR QUI?</h2>
            <p>
              Pour tout le monde et à tout âge excepté les 3 premiers mois de
              grossesse chez la femme enceinte
            </p>
            <h2>DES EXEMPLES DE BIENFAITS</h2>
            <p>
              <div className="row red">
                <ul className="re">
                  <li>Maux de dents des bébés</li>
                  <li>Névralgies dentaires des adultes</li>
                  <li>Névralgies faciales</li>
                  <li>Maux d’oreilles, bourdonnements, surdité</li>
                  <li>Otite</li>
                  <li>Conjonctivite</li>
                  <li>Sinusite</li>
                  <li>Bronchite, Rhume</li>
                  <li>Asthme</li>
                  <li>Maux d’estomac, Nausées, Vomissements</li>
                  <li>Constipation, Diarrhées</li>
                  <li>Coliques chez l’enfant, Maux de ventre des bébés</li>
                  <li>Le Foie</li>
                  <li>Énurésie</li>
                  <li>Cystite</li>
                </ul>
                <ul>
                  <li>Règles douloureuses , ovulation douloureuse</li>
                  <li>Torticolis, Douleurs cervicales</li>
                  <li>Entorses</li>
                  <li>Épaule douloureuses</li>
                  <li>Hernie discale</li>
                  <li>Sciatique</li>
                  <li>Rhumatismes dans les membres et les articulations</li>
                  <li>Pieds douloureux</li>
                  <li>Brûlure</li>
                  <li>Eczéma</li>
                  <li>Zona</li>
                  <li>
                    Système circulatoire: Ulcère des jambes, Mauvaise
                    circulation
                  </li>
                  <li>
                    Nervosité, stress, Tristesse, Angoisse, Dépression nerveuse
                  </li>
                  <li>Insomnie</li>
                  <li>Fièvre</li>
                </ul>
              </div>
            </p>
            <p>
              Le magnétisme est aussi efficace sur les animaux et les plantes!
              <br />
              Nous sommes ENERGIE et l’énergie est partout tout autour de nous!
              Laissons la venir à nous...
            </p>
            <h2>AVERTISSEMENTS</h2>
            <p>
              En tant que Thérapeute énergéticienne, Hypnothérapeute et
              Réflexologue je ne pose aucun diagnostique médical et ne fait
              aucune prescription. La thérapie énergétique, les soins
              énergétiques, l’EFT, la Réflexologie et l’Hypnose ne constituent
              pas un acte médical; ils ne dispensent pas de consulter votre
              médecin. Ils ne remplacent ni n’interrompent, et ce, EN AUCUN CAS,
              un traitement ou un suivi médical en cours. Ils ne sont qu’une
              aide complémentaire qui tente de stimuler les aptitudes naturelles
              d’autoguérison.
            </p>
          </div>
        </section>
        <div className="tarif-section">
          <h1>Tarifs pour une consultation en magnétisme</h1>
          <div className="border"></div>
          <section className="tarif-boxes">
            <div className="reverse">
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour enfant</p>
                  <p className="age">(-16ans) - 1h</p>
                </div>
                <div className="main-box">
                  <p>45€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour adulte</p>
                  <p className="age"> 1h</p>
                </div>
                <div className="main-box">
                  <p>50€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour adulte</p>
                  <p className="age"> 1h</p>
                </div>
                <div className="main-box">
                  <p>45€</p>
                  <p className="lieu">Séance à distance</p>
                </div>
              </div>
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour enfant</p>
                  <p className="age">(-16ans) - 1h</p>
                </div>
                <div className="main-box">
                  <p>40€</p>
                  <p className="lieu">Séance à distance</p>
                </div>
              </div>
            </div>
            <div className="rdv-button">
              <button>
                <Link to="/contact">Prendre rendez-vous</Link>
              </button>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Magnetisme;
