import React from "react";
import "./Soin.css";
import { Link } from "react-router-dom";
import soinimg from "../../img/soins.jpg";
import magnee from "../../img/magnee.png";
import lao from "../../img/la.png";
import eft from "../../img/eftt.png";
import relex from "../../img/faat.png";
import Main from "../../img/palmaire.png";
import faciale from "../../img/Mask4.png";
import Hypnose from "../../img/hyyp.png";
import Personel from "../../img/personel.jpeg";
import { FaFan } from "react-icons/fa";
import { Helmet } from "react-helmet";
const Soin = () => {
  return (
    <>
      <Helmet>
        <title>Les différents soins énergétiques - Mental Body Energy</title>
        <meta
          name="description"
          content="Le cabinet de soins pour le bien-être propose un large choix de pratiques pour répondre au mieux aux attentes de chacun : pratiques énergétiques, réflexologie, hypnose et autres soins."
        />
      </Helmet>
      <section className="soin-section">
        <img src={soinimg} alt="soins énergétiques" />
        <h3>Les différents soins énergétiques</h3>
        <section className="infos-soins">
          <div className="row soins">
            <div className="col info-text">
              <h2>LE MAGNÉTISME</h2>
              <p>
                Toute personne vivante est dotée d’une énergie plus ou moins
                puissante, mais accessible à tous. <br />
                Certains en sont conscients et l’utilisent pour eux-même et les
                autres, tandis que d’autres n’y parviennent pas ou l’ignorent.
                <br /> Le magnétisme est une forme d’énergie positive qui aide à
                réguler et à rééquilibrer les énergies négatives. <br /> Le soin
                s‘effectue par une apposition des mains, sans contact avec le
                corps de la personne qui reste allongée sur une table de
                massage, habillée. <br /> Le soin dure entre 30 minutes et 1
                heure. <br /> Il peut être pratiqué au cabinet comme à distance
                et l’efficacité reste la même.
              </p>
            </div>
            <Link to="/les-differents-soins-energetiques-magnetisme">
              <img
                className="magnee"
                src={magnee}
                alt="magnétisme mental body energy"
              />
            </Link>
          </div>
          <div className="row soinss">
            <Link to="/les-differents-soins-energetiques-lahotchi">
              <img src={lao} alt="lahotchi mental body energy" />
            </Link>
            <div className="col info-text">
              <h2>LE LAHOCHI</h2>
              <p>
                Le LaHoChi est une vibration de haute fréquence spirituelle qui
                permet de stimuler harmonieusement nos capacités d’auto-guérison
                physiques, psychiques et émotionnelles. Cette vibration est
                canalisée par le praticien LaHoChi qui va transmettre au
                receveur, par apposition de mains, cette énergie de
                transformation. Le thérapeute sert alors d’antenne. Le LaHoChi
                est très relaxant et les sensations ressenties sont très
                subjectives. Les bienfaits de cette très forte énergie
                continuent leur efficacité pendant 3 jours. La personne reste
                allongée habillée pendant la séance. Le praticien va procéder à
                6 appositions de mains. Le soin dure entre 40 minutes et une
                heure. Ce soin peut être pratiqué au cabinet comme à distance
                tout en gardant une réalisation optimale.
              </p>
            </div>
          </div>
          <div className="row soins">
            <div className="col info-text">
              <h2>L’EFT ( Emotional Freedom Technique )</h2>
              <p>
                L’EFT est une technique de libération émotionnelle. <br />
                C’est une thérapie de nouvelle génération qui agit sur les
                dimensions cognitives, émotionnelles et corporelles.
                <br /> Cette approche repose notamment sur la stimulation des
                méridiens, ces points d’acupressure agissant sur le système
                nerveux parasympathique. En tapotant avec les doigts certains
                points d’entrée des méridiens, tout en verbalisant le problème
                qui vous perturbe, les émotions correspondantes jusqu’alors
                stockées dans votre corps disparaissent. <br /> L’EFT peut
                traiter tous les troubles physiques ou psychiques ( douleurs,
                deuil, stress, psoriasis, zona, tristesse, culpabilité, prise de
                parole en public, phobies, addictions, la liste ne s’arrête pas
                là )...
              </p>
            </div>
            <Link to="/les-differents-soins-energetiques-eft">
              <img src={faciale} alt="EFT mental body energy" />
            </Link>
          </div>
          <div className="row soinss">
            <Link to="/les-differents-soins-energetiques-reflexologie-plantaire">
              <img
                src={relex}
                alt="Réfléxologie plantaire mental body energy"
              />
            </Link>
            <div className="col info-text">
              <h2>LA RÉFLEXOLOGIE PLANTAIRE</h2>
              <p>
                Chaque zone corporelle, organe ou glande possède sa
                correspondance au niveau du pied. On parle alors de zones
                réflexes. En appliquant des pressions sur certaines de ces
                zones, le réflexologue permet de localiser les tensions et de
                rétablir l’équilibre de la partie du corps correspondante. Ces
                stimuli exercés sur la peau vont activer le système nerveux et
                actionnent des réponses physiologiques ou psychiques. <br />
                {/* La réflexologie permet: */}
                {/* <ul>
                  <li>
                    De drainer les toxines présentes dans l'organisme et de
                    soulager certaines douleurs
                  </li>
                  <li>De gérer le stress et les émotions négatives</li>
                  <li>De soulager les migraines et les maux de tête</li>
                  <li>D’améliorer la circulation sanguine</li>
                  <li>De libérer les tensions nerveuses</li>
                  <li>De supprimer les ruminations et les pensées négatives</li>
                </ul> */}
              </p>
            </div>
          </div>
          <div className="row soins">
            <div className="col info-text">
              <h2>LA RÉFLEXOLOGIE PALMAIRE</h2>
              <p>
                la reflexologie palmaire consiste à masser les mains en
                stimulant les points réflexes de celle-ci. La main est, comme le
                pied, un reflet de notre corps et masser la main active des
                points réflexes. Comme les pieds, les mains sont la
                représentation projetée de nos organes. La réflexologie palmaire
                a une action calmante sur le système neuro-musculo-squelettique.
                Elle est complémentaire à la réflexologie plantaire en vue de
                soulager les tensions articulaires, musculaires, tissulaires, et
                d’apporter une sensation de légèreté. <br />
              </p>
            </div>
            <Link to="/les-differents-soins-energetiques-reflexologie-palmaire">
              <img src={Main} alt="Réfléxologie palmaire mental body energy" />
            </Link>
          </div>
          <div className="row soinss">
            <Link to="/les-differents-soins-energetiques-reflexologie-faciale">
              <img src={eft} alt="Réfléxologie faciale mental body energy" />
            </Link>
            <div className="col info-text">
              <h2>LA RÉFLEXOLOGIE FACIALE</h2>
              <p>
                La réflexologie faciale apporte une détente profonde des muscles
                du visage, en particulier ceux du front et de la mâchoire. Elle
                favorise la relaxation et apporte une sensation de bien-être
                général. Elle peut amener à une libération émotionnelle. Elle
                s’apparente à un massage du visage car le soin comporte des
                massages de certaines zones mais elle utilise également des
                points de pressions pour dissiper les tensions du corps. Cette
                technique apaise les traits et rend le teint plus clair. Elle
                réveille, stimule et draine le tissu conjonctif. <br />
              </p>
            </div>
          </div>
          <div className="row soins">
            <div className="col info-text">
              <h2>L'HYPNOSE</h2>
              <p>
                L’Hypnose est définie comme un état modifié de conscience qui
                permet à la personne hypnotisée d’être plus réceptive aux
                suggestions afin de résoudre une problématique physique ou
                psychique. <br />
              </p>
            </div>
            <Link to="/les-differents-soins-energetiques-hypnose">
              <img src={Hypnose} alt="Hypnose mental body energy" />
            </Link>
          </div>
          {/*  <div className="row soinss">
            <Link>
              <img src={Personel} alt="" />
            </Link>
            <div className="col info-text">
              <h2>SOINS POUR LE DÉVELOPPEMENT PERSONEL</h2>
              <p>
                Dans le monde actuel où chacun est pressé, stressé, regardé (
                via les réseaux sociaux), valorisé ou dévalorisé aux yeux des
                autres, critiqué ( que ce soit dans le sens positif comme
                négatif), évalué, gradué ou dégradé, estimé ou sous-estimé, aimé
                ou mal aimé...la société exerce sur nous une action indirecte
                sur nos sentiments et nos émotions. La conformité de tous aux
                règles collectives fait que nous oublions qui nous sommes
              </p>
            </div>
                </div> */}
        </section>
      </section>
    </>
  );
};

export default Soin;
