import React, { useState } from "react";
import "./Review.css";
import reviewI from "../../img/review.jpg";
import { Helmet } from "react-helmet";
import {
  AiFillCheckCircle,
  AiFillStar,
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
} from "react-icons/ai";
const Review = () => {
  const [textToggle, textToggleState] = useState(true);
  return (
    <>
      <Helmet>
        <title>Témoignages - Mental Body Energy</title>
        <meta
          name="description"
          content="Témoignages de mes soins énergétiques proposés du cabinet Mental Body Energy."
        />
      </Helmet>
      <div className="review-container">
        <img src={reviewI} alt="avis mental body energy" />
        <h3>Témoignages</h3>
        <section className="review-card">
          <div className="card">
            <div className="row">
              <h5>
                Bénédicte P <AiFillCheckCircle />
              </h5>
            </div>

            <div className="rating">
              <AiFillStar className="star" />
              <AiFillStar className="star" />
              <AiFillStar className="star" />
              <AiFillStar className="star" />
              <AiFillStar className="star" />
            </div>
            <h4>Très contente</h4>
            <p>
              J’ai commencé les soins avec Christine il y a deux mois. Dans un
              premier temps par le biais du magnétisme à distance. Puis j’ai pu
              faire une séance d’EFT et de réflexologie plantaire dans son
              cabinet. Les résultats sont assez bluffants. J’apprécie l’écoute,
              l’environnement très apaisant dans lequel elle nous accueille et
              surtout la diversité des techniques de soins/thérapies qu’elle
              offre pour répondre au mieux aux attentes de la clientèle. Je la
              recommande!
            </p>
          </div>
          <div className="card">
            <h5>
              Martine B <AiFillCheckCircle />
            </h5>
            <div className="rating">
              <AiFillStar className="star" />
              <AiFillStar className="star" />
              <AiFillStar className="star" />
              <AiFillStar className="star" />
              <AiFillStar className="star" />
            </div>
            <h4>Époustouflant</h4>
            <p>
              Il y a quelques mois mes jambes me faisaient souffrir. J'ai parler
              avec Mme Merlet au sujet de mes douleurs. C'est à ce moment là
              qu'elle a commencé à travailler sur mes maux Au bout de plusieurs
              mois petit à petit ces douleurs se sont estompées. Je l'en
              remercie.
            </p>
          </div>
          <div className="card">
            <h5>
              Chantal G <AiFillCheckCircle />
            </h5>
            <div className="rating">
              <AiFillStar className="star" />
              <AiFillStar className="star" />
              <AiFillStar className="star" />
              <AiFillStar className="star" />
              <AiFillStar className="star" />
            </div>
            <h4>Soulagée!</h4>
            <p>
              J’ai bien été soulagée de mes douleurs émanantes de la nuque et
              cou après les soins à distance de Christine je l’a remercie
              chaleureusement.
            </p>
          </div>
          <div className="card">
            <div className="roww sett">
              <h5>
                Elisabeth <AiFillCheckCircle />
              </h5>
              <button onClick={() => textToggleState(!textToggle)}>
                {textToggle ? (
                  <AiOutlineArrowRight className="arroww" />
                ) : (
                  <AiOutlineArrowLeft className="arroww" />
                )}
              </button>
            </div>
            <div className="rating">
              <AiFillStar className="star" />
              <AiFillStar className="star" />
              <AiFillStar className="star" />
              <AiFillStar className="star" />
              <AiFillStar className="star" />
            </div>
            <h4>Reconnaissante</h4>

            <p>
              {textToggle ? (
                <span>
                  Atteinte en août 2021 d’un zona thoracique particulièrement
                  douloureux, j’ai croisé pr hasard le chemin de Christine. Elle
                  s’est occupée de moi à plusieurs reprises et ce seulement à
                  l’aide d’une photo et d’une date de naissance. Ses
                  interventions -à distance donc- ont été à chaque fois
                  synonymes d’un grand soulagement: j’en arrivais même à oublier
                  le martyre (le mot n’est pas trop fort, je l’assure) que
                  j’endurai… malgré le Paracetamol ! Elle a également remarqué
                  que...
                </span>
              ) : (
                <span>
                  la photo que j’avais un problème à l’œil gauche et a,
                  spontanément, entrepris de me soulager de ce côté là. Mes
                  proches ont constaté que je pouvais ouvrir mon œil -que je
                  tiens habituellement à demi-fermé - et je me suis rendue
                  compte que j’étais bien plus à l’aise, que je n’avais plus
                  besoin de m’astreindre à l’ouvrir un peu… Je suis infiniment
                  reconnaissante de ces bienfaits qui m’ont redonné espoir et
                  joie de vivre et je souhaite à tous de la rencontrer au bout
                  de leur calvaire.
                </span>
              )}
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default Review;
