import React from "react";
import "./ReflexologieP.css";
import relex from "../../img/faat.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const ReflexologieP = () => {
  return (
    <>
      <Helmet>
        <title>Réflexologie plantaire - Mental Body Energy </title>
        <meta
          name="description"
          content="La réflexologie fait partie des médecines ancestrales et vient en
          complément de la médecine moderne occidentale. En aucun cas elle
          ne peut se substituer à la médecine classique. A chaque organe ..."
        />
      </Helmet>
      <section className="magne-section">
        <div className="im">
          <img src={relex} alt="réfléxologie palmaire mental body energy" />
        </div>
        <section className="infos-soins-details">
          <div className="col info-text-m">
            <h2>LA RÉFLEXOLOGIE PLANTAIRE</h2>
            <p>
              La réflexologie fait partie des médecines ancestrales et vient en
              complément de la médecine moderne occidentale. En aucun cas elle
              ne peut se substituer à la médecine classique. A chaque organe ou
              fonction physiologique correspond une zone située au niveau du
              pied. Grâce à un toucher adapté de zones en lien avec les
              troubles, le praticien dissipe les tensions afin de rétablir
              l’équilibre physique, énergétique, et émotionnel de la personne.
              C’est une technique naturelle qui vise à renforcer l’organisme et
              à améliorer le quotidien malgré la maladie. <br />
              Chaque zone corporelle, organe ou glande possède sa correspondance
              au niveau du pied. On parle alors de zones réflexes. En appliquant
              des pressions sur certaines de ces zones, le réflexologue permet
              de localiser les tensions et de rétablir l’équilibre de la partie
              du corps correspondante. Ces stimuli exercés sur la peau vont
              activer le système nerveux et actionnent des réponses
              physiologiques ou psychiques. <br /> La réflexologie permet:
              <div className="red row">
                <ul>
                  <li>
                    De drainer les toxines présentes dans l'organisme et de
                    soulager certaines douleurs
                  </li>
                  <li>De gérer le stress et les émotions négatives</li>
                  <li>De soulager les migraines et les maux de tête</li>
                  <li>D’améliorer la circulation sanguine</li>
                  <li>De libérer les tensions nerveuses</li>
                  <li>De supprimer les ruminations et les pensées négatives</li>
                </ul>
                <ul>
                  <li>De soulager les tensions du dos</li>
                  <li>
                    D’activer le système lymphatique et de décontracter les
                    muscles
                  </li>
                  <li>D’harmoniser les fonction vitale</li>
                  <li>De dynamiser l’organisme</li>
                  <li>De réduire les troubles du sommeil</li>
                  <li>De soulager des troubles digestifs</li>
                </ul>
              </div>
            </p>
            <p>
              Cette technique est également très utilisée pour soulager les
              effets secondaires de certains médicaments et améliorer la qualité
              de vie de patients atteints de maladies graves comme le cancer.
              Dès les quinze premières minutes, la réflexologie produit un état
              général de relaxation très agréable. Une séance dure une heure. Le
              patient est allongé, habillé mais les pieds nus sur une table de
              massage. Selon le problème à traiter une à plusieurs séances
              peuvent être nécessaires, espacées de 2 semaines. <br />
              La séance débute par un échange sur votre état de santé, vos
              besoins et vos attentes. Après des mouvements relaxants, un bilan
              initial permet de localiser les tissus en tension et d’établir un
              protocole de soin adapté. Par un toucher précis de certaines zones
              dites réflexes des pieds, le soin réflexologique permet à
              l’organisme de se relaxer, de se libérer du stress et des tensions
              physiques.
            </p>
            <h2>DES EXEMPLES DE BIENFAITS</h2>
            <p>
              <div className="row red">
                <ul>
                  <li>
                    Les maux de dos, pincements musculaires, lombalgie,,
                    lumbago, hernie discale
                  </li>
                  <li>Les torticolis, tensions cervicales</li>
                  <li>Les douleurs articulaires</li>
                  <li>Les troubles de l’humeur</li>
                  <li>
                    Les troubles du sommeil, les insomnies, les cauchemars
                  </li>
                  <li>Les crampes</li>
                  <li>
                    Les problèmes circulatoires, jambes lourdes, rétention
                    d’eau, varices
                  </li>
                  <li>Les céphalées, maux de tête, migraines</li>
                  <li>Les ruminations, les pensées obsédantes</li>
                </ul>
                <ul>
                  <li>
                    Les douleurs abdominales, vomissements, brûlures gastriques,
                    diarrhées ou constipation
                  </li>
                  <li>Le stress, les angoisses, les tensions nerveuses</li>
                  <li>
                    Les maladies ORL, les sinusites, nez bouché, saignements du
                    nez
                  </li>
                  <li>Les maux de gorge, enrouement, extinction de voix</li>
                  <li>Les inflammations des bronches et des poumons</li>
                  <li>L’asthme</li>
                  <li>
                    Les tensions et contractures musculaires ( nuque, épaule,
                    genou, hanche)
                  </li>
                  <li>Les douleurs du canal carpien</li>
                  <li>Les sciatiques</li>
                  <li>…...et bien d’autres encore…</li>
                </ul>
              </div>
              <h2>CONTRE INDICATIONS</h2>
              <p>
                <ul>
                  <li>Blessures et inflammation du pied</li>
                  <li>Verrues ou problème de peau sur le pied</li>
                  <li>
                    Problèmes circulatoires graves et non résolus tels que
                    thrombose et phlébite
                  </li>
                  <li>Affections cardiaques graves</li>
                  <li>3 premiers mois de grossesse</li>
                </ul>
              </p>
            </p>
            <h2>AVERTISSEMENTS</h2>
            <p>
              En tant que Thérapeute énergéticienne, Hypnothérapeute et
              Réflexologue je ne pose aucun diagnostique médical et ne fait
              aucune prescription. La thérapie énergétique, les soins
              énergétiques, l’EFT, la Réflexologie et l’Hypnose ne constituent
              pas un acte médical; ils ne dispensent pas de consulter votre
              médecin. Ils ne remplacent ni n’interrompent, et ce, EN AUCUN CAS,
              un traitement ou un suivi médical en cours. Ils ne sont qu’une
              aide complémentaire qui tente de stimuler les aptitudes naturelles
              d’autoguérison.
            </p>
          </div>
        </section>
        <div className="tarif-section">
          <h1>Tarifs pour une consultation en réflexologie plantaire</h1>
          <div className="border"></div>
          <section className="tarif-boxes">
            <div className="row">
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour enfant</p>
                  <p className="age">(-16ans) - 1h</p>
                </div>
                <div className="main-box">
                  <p>45€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour adulte</p>
                  <p className="age"> 1h</p>
                </div>
                <div className="main-box">
                  <p>50€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
            </div>
            <div className="rdv-button">
              <button>
                <Link to="/contact">Prendre rendez-vous</Link>
              </button>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default ReflexologieP;
