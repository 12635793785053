import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Header from "./component/header/header";
import Footer from "./component/Footer/Footer";
import Home from "./container/Home/Home";
import Quisuisje from "./container/Quisuisje/Quisuisje";
import Soin from "./container/Soin/Soin";
import Magnetisme from "./component/Magnetisme/Magnetisme";
import Lahotchi from "./component/Lahotchi/Lahotchi";
import ReflexologieP from "./component/ReflexologieP/ReflexologieP";
import ReflexologieM from "./component/ReflexologieM/ReflexologieM";
import ReflexologieF from "./component/ReflexologieF/ReflexologieF";
import Contact from "./container/Contact/Contact";
import Hypnose from "./component/Hypnose/Hypnose";
import EFT from "./component/EFT/EFT";
import Review from "./container/Review/Review";
import ScrollToTop from "./ScrollToTop";
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route path="/temoignages">
          <Review />
        </Route>
        <Route path="/les-differents-soins-energetiques-eft">
          <EFT />
        </Route>
        <Route path="/les-differents-soins-energetiques-hypnose">
          <Hypnose />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/les-differents-soins-energetiques-reflexologie-faciale">
          <ReflexologieF />
        </Route>
        <Route path="/les-differents-soins-energetiques-reflexologie-palmaire">
          <ReflexologieM />
        </Route>
        <Route path="/les-differents-soins-energetiques-reflexologie-plantaire">
          <ReflexologieP />
        </Route>
        <Route path="/les-differents-soins-energetiques-lahotchi">
          <Lahotchi />
        </Route>
        <Route path="/les-differents-soins-energetiques-magnetisme">
          <Magnetisme />
        </Route>
        <Route path="/les-differents-soins-energetiques">
          <Soin />
        </Route>
        <Route path="/qui-suis-je">
          <Quisuisje />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
