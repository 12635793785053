import React from "react";
import "./Lahotchi.css";
import lao from "../../img/la.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const Lahotchi = () => {
  return (
    <>
      <Helmet>
        <title>Lahochi - Mental Body Energy </title>
        <meta
          name="description"
          content="Le LaHoChi est une vibration de haute fréquence spirituelle qui
          permet de stimuler harmonieusement nos capacités d’auto-guérison
          physiques, psychiques et émotionnelles."
        />
      </Helmet>
      <section className="magne-section">
        <div className="im">
          <img src={lao} alt="lahotchi mental body energy" />
        </div>
        <section className="infos-soins-details">
          <div className="col info-text-m">
            <h2>LE LAHOCHI</h2>
            <p>
              Le LaHoChi est une vibration de haute fréquence spirituelle qui
              permet de stimuler harmonieusement nos capacités d’auto-guérison
              physiques, psychiques et émotionnelles. Cette vibration est
              canalisée par le praticien LaHoChi qui va transmettre au receveur,
              par apposition de mains, cette énergie de transformation. Le
              thérapeute sert alors d’antenne. Le LaHoChi est très relaxant et
              les sensations ressenties sont très subjectives. Les bienfaits de
              cette très forte énergie continuent leur efficacité pendant 3
              jours. La personne reste allongée habillée pendant la séance. Le
              praticien va procéder à 6 appositions de mains. Le soin dure entre
              40 minutes et une heure. Ce soin peut être pratiqué au cabinet
              comme à distance tout en gardant une réalisation optimale.
            </p>
            <p>
              Le Praticien LaHoChi ne guérit strictement personne, il sert
              d’antenne et met à disposition du consultant l’énergie universelle
              de vie. Le praticien prend soin énergétiquement de son receveur.
              l’énergie fait ensuite son travail afin de dissoudre les blocages
              énergétiques responsables de déséquilibres physiques, mentaux,
              émotionnels mais aussi spirituels. <br />
              Le LaHoChi ne peut interférer ou se substituer à un avis,
              prescription ou traitement médical, et ce, en aucune façon.
            </p>
            <h2>DES EXEMPLES DE BIENFAITS</h2>
            <p>
              Le LaHoChi retisse le champs d’énergie en réparant les brèches,
              les déchirures et les régions endommagées par une variété
              d’influence incluant
              <div className="red row">
                <ul>
                  <li>Une naissance difficile</li>
                  <li>Une opération chirurgicale</li>
                  <li>La radiation</li>
                  <li>La drogue</li>
                  <li>Les accidents</li>
                  <li>Les blessures anciennes et présentes de la vie</li>
                  <li>Tous types de traumatismes</li>
                  <li>Les intoxications environnementales</li>
                  <li>
                    Les programmations négatives et les tendances génétiques
                  </li>
                </ul>
                <ul>
                  <li>
                    Il améliore, dans un champs éthérique, un traitement
                    d’acupuncture et de réflexologie
                  </li>
                  <li>Il induit des vibrations de couleur et de santé</li>
                  <li>
                    Il équilibre la circulation du fluide crânien dans le
                    cerveau et équilibre le système cérébro-spinal
                  </li>
                  <li>Il ouvre et nettoie le système des chakras</li>
                  <li>Il élargie le champ d’énergie</li>
                  <li>Il réaligne la structure du corps</li>
                  <li>Il accélère la guérison</li>
                  <li>
                    Il assiste à atteindre les plus hauts états de méditation ou
                    de conscience possible pour chaque personne dans le moment
                    présent
                  </li>
                  <li>Il augmente votre taux vibratoire</li>
                  <li>et plus encore....VOUS SEREZ SURPRIS!</li>
                </ul>
              </div>
            </p>
            <p>
              Le LaHoChi se pratique au cabinet en présentiel mais aussi à
              distance où le soin est tout aussi efficace. Le receveur reste
              allongé tout au long de la séance entre 40 et 50 minutes et ses
              ressentis lors de la passation de l’énergie est très subjective
              mais elle est toujours très agréable et relaxante. L’énergie
              LaHoChi est encore active pendant 2 à 3 jours après le soin.
            </p>
            <h2>AVERTISSEMENTS</h2>
            <p>
              En tant que Thérapeute énergéticienne, Hypnothérapeute et
              Réflexologue je ne pose aucun diagnostique médical et ne fait
              aucune prescription. La thérapie énergétique, les soins
              énergétiques, l’EFT, la Réflexologie et l’Hypnose ne constituent
              pas un acte médical; ils ne dispensent pas de consulter votre
              médecin. Ils ne remplacent ni n’interrompent, et ce, EN AUCUN CAS,
              un traitement ou un suivi médical en cours. Ils ne sont qu’une
              aide complémentaire qui tente de stimuler les aptitudes naturelles
              d’autoguérison.
            </p>
          </div>
        </section>
        <div className="tarif-section">
          <h1>Tarifs pour une consultation en lahotchi</h1>
          <div className="border"></div>
          <section className="tarif-boxes">
            <div className="row">
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour enfant</p>
                  <p className="age">(-16ans) - 1h</p>
                </div>
                <div className="main-box">
                  <p>45€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour adulte</p>
                  <p className="age"> 1h</p>
                </div>
                <div className="main-box">
                  <p>50€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour adulte</p>
                  <p className="age"> 1h</p>
                </div>
                <div className="main-box">
                  <p>45€</p>
                  <p className="lieu">Séance à distance</p>
                </div>
              </div>
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour enfant</p>
                  <p className="age">(-16ans) - 1h</p>
                </div>
                <div className="main-box">
                  <p>40€</p>
                  <p className="lieu">Séance à distance</p>
                </div>
              </div>
            </div>
            <div className="rdv-button">
              <button>
                <Link to="/contact">Prendre rendez-vous</Link>
              </button>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Lahotchi;
