import React from "react";
import "./Contact.css";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet";
const Contact = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wlqnlg5",
        "template_kkysm5o",
        e.target,
        "user_5euvvx6eBsKH9v94JoeMM"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact - Mental Body Energy</title>
        <meta
          name="description"
          content="Prenez rendez vous dès maintenant à mon cabinet aux Lucs sur Boulogne ou à distance pour une séance d'un de mes soins énergétiques proposés."
        />
      </Helmet>
      <h2>
        <a class="link--manko" href="#">
          Contact
        </a>
      </h2>
      <h5>Prenez rendez-vous dès maintenant</h5>
      <section className="input-section">
        <form onSubmit={sendEmail}>
          <div className="row">
            <span class="input input--nao">
              <input
                class="input__field input__field--nao"
                type="text"
                id="input-1"
                name="name"
              />
              <label class="input__label input__label--nao" for="input-1">
                <span class="input__label-content input__label-content--nao">
                  Prénom
                </span>
              </label>
              <svg
                class="graphic graphic--nao"
                width="300%"
                height="100%"
                viewBox="0 0 1200 60"
                preserveAspectRatio="none"
              >
                <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
              </svg>
            </span>
            <input
              type="hidden"
              name="_next"
              value="http://localhost:3000/contact"
            ></input>
            <span class="input input--nao">
              <input
                class="input__field input__field--nao"
                type="text"
                id="input-1"
                name="nom"
              />
              <label class="input__label input__label--nao" for="input-1">
                <span class="input__label-content input__label-content--nao">
                  Nom
                </span>
              </label>
              <svg
                class="graphic graphic--nao"
                width="300%"
                height="100%"
                viewBox="0 0 1200 60"
                preserveAspectRatio="none"
              >
                <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
              </svg>
            </span>
          </div>
          <input type="hidden" name="_captcha" value="false" />
          <div className="row">
            <span class="input input--nao">
              <input
                class="input__field input__field--nao"
                type="text"
                id="input-1"
                name="email"
              />
              <label class="input__label input__label--nao" for="input-1">
                <span class="input__label-content input__label-content--nao">
                  Email
                </span>
              </label>
              <svg
                class="graphic graphic--nao"
                width="300%"
                height="100%"
                viewBox="0 0 1200 60"
                preserveAspectRatio="none"
              >
                <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
              </svg>
            </span>
            <span class="input input--nao">
              <input
                class="input__field input__field--nao resize"
                type="text"
                id="input-1"
                name="tel"
              />
              <label class="input__label input__label--nao" for="input-1">
                <span class="input__label-content input__label-content--nao">
                  Téléphone
                </span>
              </label>
              <svg
                class="graphic graphic--nao"
                width="300%"
                height="100%"
                viewBox="0 0 1200 60"
                preserveAspectRatio="none"
              >
                <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
              </svg>
            </span>
          </div>
          <div className="row">
            <span class="input input--nao">
              <input
                class="input__field input__field--nao resize"
                type="text"
                id="input-1"
                name="subject"
              />
              <label class="input__label input__label--nao" for="input-1">
                <span class="input__label-content input__label-content--nao">
                  Votre message
                </span>
              </label>
              <svg
                class="graphic graphic--nao"
                width="300%"
                height="100%"
                viewBox="0 0 1200 60"
                preserveAspectRatio="none"
              >
                <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
              </svg>
            </span>
            <span class="input input--nao">
              <input
                class="input__field input__field--nao resize"
                type="text"
                id="input-1"
                name="probleme"
              />
              <label class="input__label input__label--nao" for="input-1">
                <span class="input__label-content input__label-content--nao">
                  Symptomes
                </span>
              </label>
              <svg
                class="graphic graphic--nao"
                width="300%"
                height="100%"
                viewBox="0 0 1200 60"
                preserveAspectRatio="none"
              >
                <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
              </svg>
            </span>
          </div>
          <span class="input input--nao">
            <input
              class="input__field input__field--nao resize"
              type="text"
              id="input-1"
              name="date"
            />
            <label class="input__label input__label--nao" for="input-1">
              <span class="input__label-content input__label-content--nao">
                Disponibilités
              </span>
            </label>
            <svg
              class="graphic graphic--nao"
              width="300%"
              height="100%"
              viewBox="0 0 1200 60"
              preserveAspectRatio="none"
            >
              <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
            </svg>
          </span>
          <div className="end">
            <button
              class="button button--rayen button--border-thin button--text-thick button--text-upper button--size-s"
              data-text="Envoyer"
            >
              <span>Envoyer</span>
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Contact;
