import React from "react";
import "./ReflexologieF.css";
import faciale from "../../img/eftt.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const ReflexologieF = () => {
  return (
    <>
      <Helmet>
        <title>Réflexologie faciale - Mental Body Energy </title>
        <meta
          name="description"
          content="La réflexologie faciale apporte une détente profonde des muscles
          du visage, en particulier ceux du front et de la mâchoire."
        />
      </Helmet>
      <section className="magne-section">
        <div className="im">
          <img src={faciale} alt="réfléxologie faciale mental body energy" />
        </div>
        <section className="infos-soins-details">
          <div className="col info-text-m">
            <h2>LA RÉFLEXOLOGIE FACIALE</h2>
            <p>
              La réflexologie faciale apporte une détente profonde des muscles
              du visage, en particulier ceux du front et de la mâchoire. Elle
              favorise la relaxation et apporte une sensation de bien-être
              général. Elle peut amener à une libération émotionnelle. Elle
              s’apparente à un massage du visage car le soin comporte des
              massages de certaines zones mais elle utilise également des points
              de pressions pour dissiper les tensions du corps. Cette technique
              apaise les traits et rend le teint plus clair. Elle réveille,
              stimule et draine le tissu conjonctif.
            </p>
            <h2>DES EXEMPLES DE BIENFAITS</h2>
            <p>
              <ul>
                <li>Les acouphènes</li>
                <li>Les maux de tête</li>
                <li>Les grincements et serrements de dents</li>
                <li>Les fatigues oculaires</li>
                <li>Les troubles ORL</li>
                <li>
                  Les douleurs et tensions musculaires de la tête, de la nuque
                  et du haut du corps - la sensibilisation à l’image de soi et à
                  son schéma corporel
                </li>
              </ul>
            </p>
            <h2>AVERTISSEMENTS</h2>
            <p>
              En tant que Thérapeute énergéticienne, Hypnothérapeute et
              Réflexologue je ne pose aucun diagnostique médical et ne fait
              aucune prescription. La thérapie énergétique, les soins
              énergétiques, l’EFT, la Réflexologie et l’Hypnose ne constituent
              pas un acte médical; ils ne dispensent pas de consulter votre
              médecin. Ils ne remplacent ni n’interrompent, et ce, EN AUCUN CAS,
              un traitement ou un suivi médical en cours. Ils ne sont qu’une
              aide complémentaire qui tente de stimuler les aptitudes naturelles
              d’autoguérison.
            </p>
          </div>
        </section>
        <div className="tarif-section">
          <h1>Tarifs pour une consultation en réflexologie faciale</h1>
          <div className="border"></div>
          <section className="tarif-boxes">
            <div className="row">
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour enfant</p>
                  <p className="age">(-16ans) - 1h</p>
                </div>
                <div className="main-box">
                  <p>45€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
              <div className="col size-box">
                <div className="header-box">
                  <p>Séance pour adulte</p>
                  <p className="age"> 1h</p>
                </div>
                <div className="main-box">
                  <p>50€</p>
                  <p className="lieu">Séance aux Lucs</p>
                </div>
              </div>
            </div>
            <div className="rdv-button">
              <button>
                <Link to="/contact">Prendre rendez-vous</Link>
              </button>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default ReflexologieF;
