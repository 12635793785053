import React from "react";
import "./Home.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import relaxing from "../../img/relaxing.jpg";
import naples from "../../img/napoli.jpg";
import { Helmet } from "react-helmet";
const Home = () => {
  const settings = {
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Helmet>
        <title>Mental Body Energy - Christine énergéticienne</title>
        <meta
          name="description"
          content="Les Médecines Douces proposent une alternative non médicamenteuses et sans effets secondaires aux douleurs physiques et psychiques."
        />
      </Helmet>
      <section className="home">
        <Slider className="carousel" {...settings} autoplay={true}>
          <div>
            <img src={relaxing} alt="energeticienne, les lucs" />
          </div>
          <div>
            <img src={naples} alt="energeticienne; Naples" />
          </div>
        </Slider>
        <h1>
          Thérapeute en soins énergétiques et en accompagnement vers le
          bien-être: <br />
          <h2>
            Énergéticienne, Praticienne en LaHoChi, Praticienne en EFT,
            Réflexologue, Hypnothérapeute
          </h2>
        </h1>
        <section className="info-home">
          <h3>Christine</h3>
          <div></div>
          <p>
            La maladie ( physique ou psychique ) est un déséquilibre dans le
            champs énergétique d’un individu. Au cours d’une séance le
            thérapeute dirige et module ce champs énergétique. <br />
            En définitive le praticien fait office de système de soutien fondé
            sur l’énergie humaine jusqu’à ce que le système immunitaire du
            patient lui-même soit assez solide pour prendre le relais. Car en
            fait c’est le patient lui-même qui se guérit.
          </p>
          <h4>POUR QUOI FAIRE? </h4>
          <p>
            Les soins énergétiques permettent de comprendre vos blocages et à
            les dépasser ( peurs, phobies, état émotionnel, symptômes
            psychosomatiques ). <br /> Les soins énergétiques soulagent
            également les douleurs physiques, les rhumatismes, les troubles du
            métabolisme; ils améliorent le fonctionnement du système digestif et
            de l’appareil génito-urinaire, la liste n’est pas exhaustive...
          </p>
          <h4>POUR QUI?</h4>
          <p className="liste-home">
            Pour tout le monde et à tout âge!
            <ul>
              <li>Bébé</li>
              <li>Femme enceinte ( après le 3ème mois de grossesse)</li>
              <li>Sportif</li>
              <li>Hommes et Femmes en activité</li>
              <li>Seniors</li>
              <li>Personnes en traitements lourds</li>
            </ul>
          </p>
          <h4>Les Résultats</h4>
          <p>
            Dès la fin de la première séance vous allez vous sentir plus léger.
            Ensuite les changements peuvent être immédiats ou se faire ressentir
            de manière plus subtile quelques jour plus tard.Les résultats sont
            subjectifs mais dépendent aussi des problématiques à traiter. Ainsi
            le psoriasis ne peut pas être traité en une seule séance par
            exemple. Un torticolis sera résolu en une séance. Certaines
            problématiques disparaîtront après plusieurs consultations espacées
            de 3 à 4 semaines.
          </p>
        </section>
      </section>
    </>
  );
};

export default Home;
